import React from 'react';

const ContestantInfo = ({ image, contestantName, id, radio, description, candidateChange }) => {
    return (
        <div className="choose">
            <label className="containers">
                <input type="radio" name={radio} onChange={candidateChange} value={id} />
                <span className="checkmarks"></span>
            </label>
            <img src={image} alt="contestant" width="110px" className="img" />
            <article>
                <p>{contestantName}</p>
                <p>{description}</p>
            </article>
        </div>
    );
};

export default ContestantInfo;