import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Cookies from "js-cookie"
import { serverurl, Voters } from '../../../api';
import Toast from '../../../components/Toast';
import Swal from 'sweetalert2';
import PreLoader from '../../../components/PreLoader';


const UserStatus = () => {
    const [voters, setVoters] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
            navigate("/admin/login")
        }

        Voters.getVoters()
        .then((res) => {
            setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message})
                .then(() => navigate("/admin/dashboard"));
            }
            setVoters(res.voters);
        })
        .catch((err) => {
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message})
            .then(() => navigate("/admin/dashboard"));;
        })
    }, [])

    const approveVoter = (id) => {
        const voterName = voters.find(v => v.id == id).name;
        Swal.fire({
            icon: "info",
            title: "Confirm",
            text: "Are you sure you want to approve " + voterName.toUpperCase() + " as a valid VOTER? This cannot be reversed!",
            confirmButtonText: "Yes, Approve",
            showDenyButton: true,
            denyButtonColor: "red"
        })
        .then(res => {
            if (res.isConfirmed) {
                Voters.approveVoter({voterID: id})
                .then((res) => {
                setLoading(false);
                    if (res.type !== "success") {
                        Toast.fire({icon: "error", title: res.message});
                    }
                    Toast.fire({icon: "success", title: res.message})
                    .then(() => {
                        Voters.getVoters()
                        .then((res) => {
                            if (res.type !== "success") {
                                Toast.fire({icon: "error", title: res.message})
                                .then(() => navigate("/admin/dashboard"));
                            }
                            setVoters(res.voters);
                        })
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    const message = err.response?.data.message || "Network error";
                    Toast.fire({icon: "error", title: message});
                })

            }
        })
    }


    return (
        <section className="welcome con">
        {
            loading && <PreLoader message={"Loading registered voters"} />
        }
        <nav className="nav">
            <ul>
                <li><Link to="/admin/dashboard" className="side">Dashboard</Link></li>
                <hr/>
                <li><Link to="/admin/posts" className="side" >Post</Link></li>
                <hr/>
                <li><Link to="/admin/contestants" className="side">Contestants</Link></li>
                <hr/>
                <li><Link to="/admin/result" className="side">Result</Link></li>
                <hr/>
                <li><Link to="/admin/status" className="side">Voters</Link></li>
            </ul>                   
        </nav>
        <section className='table'>
            <div>
               <h1>VOTERS</h1> 
               <Link to="/admin/addvoter" className="active">Add Voter</Link>
            </div>
            
            {
                loading && <h3 style={{textAlign:"center"}}>Fetching voters...</h3>
            }
            {/* <form> */}
                <tr>
                    <th>S/N</th>
                    <th>NAME</th>
                    <th>Email</th>
                    <th>Date Registered</th>
                    <th>Reg. Status</th>
                    <th>Vote Status</th>
                    <th>Action</th>
                </tr>
                {
                    voters.map((voter, id) => {
                        return (
                            <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{voter.name}</td>
                                <td>{voter.email}</td>
                                <td>{new Date(voter.date_registered).toLocaleDateString()}</td>
                                <td>{voter.approved ? "APPROVED" : "PENDING"}</td>
                                <td>{voter.voted ? "VOTED" : "PENDING"}</td>
                                <td>
                                    <Link to={`${serverurl}/receipts/${voter.receipt_url}`} className='btn'>View Receipt</Link>
                                    {
                                        !voter.approved && <button className='active' onClick={() => approveVoter(voter.id)}>Approve</button>
                                    }
                                    
                                </td>
                            </tr>
                        )
                    })
                }
            {/* </form> */}
        </section>
        </section>
    );
};

export default UserStatus;