import http from "axios";
import Cookies from "js-cookie";

// const serverurl = "http://localhost:7001";
const serverurl = "https://naossecserver.prodiscale.com";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("voting-tk");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                // "Content-Type": "application/x-www-formurlencoded",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;
const request = {
    get: (url, params) => http.get(`${serverurl}${url}`, requestOptions("application/json") ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    upload: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/x-www-form-urlencoded")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, data, requestOptions("application/json")).then(response)
}

const Voters = {
    login: (params)=> request.post("/voters/login", params),
    signup: (params)=> request.upload("/voters/signup", params),
    getVoters: (params)=> request.get("/voters"),
    addVoter: (params)=> request.upload("/voters", params), 
    approveVoter: (params)=> request.post("/voters/approve", params)
}

const Vote = {
    getVoteResults: (params)=> request.get("/votes/results", params),
    getVotes: (params)=> request.get("/votes", params),
    castVote: (params)=> request.post("/votes/cast", params),
    updateVoteSetting: (params)=> request.put("/votes/update-setting", params),
    getVoteSetting: (params)=> request.get("/votes/setting", params)
}

const Position = {
    getPositions: (params)=> request.get("/positions", params),
    addPosition: (params)=> request.post("/positions", params)
}

const Candidate = {
    getCandidates: (params)=> request.get("/candidates", params),
    addCandidate: (params)=> request.upload("/candidates", params)
}

const Admin = {
    login: (params)=> request.post("/admins/login", params),
}



export {Vote, Voters, Admin, Position, Candidate, serverurl};