import React from 'react';
import ContestantInfo from "./contestantinfo";
import userImg from "../../../user.jpg"
import { serverurl } from '../../../api';

const PositionTemplate = ({ title, showTitle, post, contestants, candidateChange }) => {

    return (
        <>
        {showTitle && <h1 className='title'>{title}</h1>}
        <section className="all position">
            <section>
                <div className="log">
                    <h1>{post}</h1>
                </div>
                <p>List of contestant for {post} position</p>
                <div>
                    <p>INSTRUCTIONS</p>
                    <article className='instruction'>
                        <span>Select</span>
                        <span className='num'>1</span>
                        <span>option</span>
                        <span>from</span>
                        <span>the</span> 
                        <span>list</span>
                        <span>below.</span> 
                        <span className='req'>* Required</span>
                    </article>
                </div>
                <hr/>
                {contestants.map((contestant, index) => (
                <ContestantInfo
                    key={index} 
                    image={contestant.profile_pic_url ? `${serverurl}/candidates_pics/${contestant.profile_pic_url}` : userImg} 
                    contestantName={contestant.first_name + " " + contestant.last_name}
                    radio={contestant.position}
                    description={contestant.nick_name} 
                    candidateChange={candidateChange}
                    id={contestant.id}
                />
            ))}
            </section>
        </section>
        </>
        
    )
}

export default PositionTemplate;