import { Link ,useNavigate } from "react-router-dom";
import Toast from "../../components/Toast";
import Cookies from "js-cookie";
import { Voters } from "../../api";
import { useState } from "react";
import logo from "../../logo.png"


const LoginPage = () => {

    const [form, setForm] = useState({email: "", voterid: ""});
    const [loading , setLoading] = useState(false);

    const navigate = useNavigate();

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!form.email || !form.voterid) {
            return Toast.fire({icon: "error", title: "Please provide valid VoterID and Email address."})
        }
        setLoading(true);

        Voters.login(form)
        .then((res) => {
        setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message});
            }
            Cookies.set("voter-tk", res.token);
            Cookies.set("voting-tk", res.token);
            Toast.fire({icon: "success", title: res.message})
            .then(() => navigate("/vote"));
        })
        .catch((err) => {
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message});
        })
    }


    return (
        <>  
        <p style={{textAlign: "center"}}>
                    <img src={logo} alt="NAOSS logo" width={200} />
            </p>
        <section className="all">

          
            <div className="log">
                <h1 style={{textAlign: "center"}}>NAOSSEC Voter Login</h1>    
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <p>Email</p>
                    <input type="email" name="email" placeholder="Email" onChange={inputChange} />
                </div>
                <div>
                    <p>Voter ID <span style={{color: "blue", fontSize: "0.7rem"}} title="The Voter ID sent to your provided email address after accreditation"><u>What is this?</u></span></p>
                    <input type="text" name="voterid" placeholder="Voter ID" onChange={inputChange} />
                </div>
                <div>
                    <input type="submit" className="link login" value={loading ? "Logging in.." : "Login"} />
                </div>
            </form>
            <p style={{textAlign: "center", padding: "10px"}}>Not accredited? <Link to={"/"}>Register to get Accredited</Link></p>
        </section>
        </>
        
    );
}

export default LoginPage;