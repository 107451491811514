import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Voters } from "../../api";
import Toast from "../../components/Toast";
import logo from "../../logo.png"


const AccreditialsPage = () => {
    const [form, setForm] = useState({email: "", name: "", receipt: {}});
    const [loading , setLoading] = useState(false);

    const navigate = useNavigate();

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const fileChange = (e) => {
        console.log(e.target.files);
        setForm({...form, receipt: e.target.files[0]});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("email", form.email);
        formData.append("receipt", form.receipt);

        Voters.signup(formData)
        .then((res) => {
        setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: res.message})
            .then(() => navigate("/login"));
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message});
        })
    }

    return (
        <>
        <p style={{textAlign: "center"}}>
                <img src={logo} alt="NAOSS logo" width={200} />
        </p>
        <section className="all">
            <div className="log">
                <h1 style={{textAlign: "center"}}>NAOSSEC Voter Accreditation</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <p>Name</p>
                    <input type="text" name="name" placeholder="Name" onChange={inputChange} />
                </div>
                <div>
                    <p>Email</p>
                    <input type="email" name="email" placeholder="Email" onChange={inputChange} />
                </div>
                <div>
                    <p>Payment</p>
                    <input type="file" name="payment" onChange={fileChange} />
                </div>
                <div>
                    <input type="submit" className="link login" value={loading ? "Submitting..." : "Submit"} />
                </div>
                
            </form>
            <p style={{textAlign: "center", padding: "10px"}}>Already accredited? <Link to={"/login"}>Login to Vote</Link></p>
        </section>
        </>
        
    );
}

export default AccreditialsPage;