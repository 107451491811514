

const PreLoader = ({message}) => {
    
    return (
        <div class="loader-container">
            <div>
                <p>{message}...</p>
                <div class="rotator"></div>
            </div>
        </div>
    )
}

export default PreLoader;