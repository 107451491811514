import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Admin } from "../../../api";
import Toast from "../../../components/Toast";

const AdminLoginPage = () => {
    const [form, setForm] = useState({email: "", password: ""});
    const [loading , setLoading] = useState(false);

    const navigate = useNavigate();

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        Admin.login(form)
        .then((res) => {
        setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message});
            }
            Cookies.set("admin-tk", res.token);
            Cookies.set("voting-tk", res.token);
            Toast.fire({icon: "success", title: res.message})
            .then(() => navigate("/admin/dashboard"));
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message});
        })
    }

    return (
        <section className="all">
            <div className="log">
                <h1>Admin Login</h1>    
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <p>Email</p>
                    <input type="email" name="email"  onChange={inputChange} />
                </div>
                <div>
                    <p>Password</p>
                    <input type="password" name="password" onChange={inputChange}/>
                </div>
                <div>
                    <input type="submit" className="link login" value={loading ? "Logging in...": "Login"} />
                </div>
            </form>
        </section>
    );
}

export default AdminLoginPage;