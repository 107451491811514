import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Vote } from "../../../api";
import Toast from "../../../components/Toast";

const AdminDashboard = () => {
    const navigate = useNavigate();
    const [voteStatus, setVoteStatus] = useState(false);

    useEffect(() => {
        if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
            return navigate("/admin/login")
        }


        Vote.getVoteSetting()
        .then((res) => {
           if (res.type === "error") {
               return Toast.fire({icon: "error", title: res.message});
           }
           setVoteStatus(res.voteStatus);
        })
        .catch((err) => {
           const message = err.response?.data.message || "Network error";
           Toast.fire({icon: "error", title: message})
           .then(() => navigate("/admin/login"));
        })

    }, [])

    const logout = () => {
        Cookies.remove("admin-tk");
        Cookies.remove("voting-tk");
        navigate("/admin/login");
    }

    const updateVoteSetting = () => {
         Vote.updateVoteSetting({setting: voteStatus ? "off" : "on"})
         .then((res) => {
            if (res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            setVoteStatus(!voteStatus);
            Toast.fire({icon: "success", title: res.message});
         })
         .catch((err) => {
            console.log(err);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message});
         })
    }

    return (
        <div className="welcome">
            <nav>
                <ul>
                    <li><Link to="/admin/dashboard" className="side" >Dashboard</Link></li>
                    <hr />
                    <li><Link to="/admin/posts" className="side" >Post</Link></li>
                    <hr/>
                    <li><Link to="/admin/contestants" className="side">Contestants</Link></li>
                    <hr/>
                    <li><Link to="/admin/result" className="side">Result</Link></li>
                    <hr/>
                    <li><Link to="/admin/status" className="side">Voters</Link></li>
                </ul>
            </nav>
            <div className="begin">
                <h1>WELCOME</h1>
                <button className="active" onClick={logout}>Logout</button>
                <h2>{voteStatus ? "VOTING IS CURRENTLY ONGOING." : "VOTING IS CURRENTLY OFF."}</h2>
                <article className="start">
                    <br/>
                    <p>{voteStatus ? "End Vote" : "Start Vote"}</p>
                    <label className="switch">
                        <input type="checkbox" onChange={updateVoteSetting} checked={voteStatus}/>
                        <span className="slider round"></span>
                    </label>
                </article>
            </div>
        </div>
    );
}

export default AdminDashboard;
