import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Candidate, Position } from "../../../api";
import Toast from "../../../components/Toast";

const AddContestantPage = () => {
    const [positions, setPositions] = useState([]);
    const [form, setForm] = useState({fname: "", lname: "", nickName: "", positionID: "", candidate_pic: {}});
    const [loading , setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
            return navigate("/admin/login")
        }

        Position.getPositions()
        .then((res) => {
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message});
            }
            setForm({...form, positionID: res.positions[0].id})
            setPositions(res.positions);
        })
        .catch((err) => {
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message})
            .then(() => navigate("/admin/login"));;
        })
    }, [])

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const fileupload = (e) => {
        setForm({...form, candidate_pic: e.target.files[0]});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        for (const key in form) {
            if (Object.hasOwnProperty.call(form, key)) {
                const data = form[key];
                formData.append(key, data);
            }
        }
        
        Candidate.addCandidate(formData)
        .then((res) => {
        setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: res.message})
            .then(() => navigate("/admin/contestants"));
        })
        .catch((err) => {
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message})
        })
    }

    return (
        <section className="all">
            <div className="log">
                <h1>Add Contestant</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <p>First Name</p>
                    <input type="text" name="fname" placeholder="First Name" onChange={inputChange} />
                </div>
                <div>
                    <p>Last Name</p>
                    <input type="text" name="lname" placeholder="Last Name" onChange={inputChange} />
                </div>
                <div>
                    <p>Nickname</p>
                    <input type="text" name="nickName" placeholder="Nickname" onChange={inputChange} />
                </div>
                <div>
                    <p>Position</p>
                    <select id="dropdown" onChange={inputChange} name="positionID" value={form.positionID}>
                         {
                            positions.map((post, id) => {
                                return <option value={post.id} key={id}>{post.title}</option>
                            })
                         }
                        
                    </select>
                    <p className="not">Not here <Link to="/admin/addpost" className="">Add Post</Link></p>
                </div>
                <div>
                    <p>Image</p>
                    <input type="file" name="payment" onChange={fileupload}/>
                </div>
                <div>
                    <input type="submit" value={loading ? "Adding Contestant..." : "Add Contestant"} />
                </div>
                
            </form>
            <p style={{textAlign: "center", padding: "10px"}}>&lt;&lt;<Link to={-1}>Go Back</Link></p>
        </section>
        
    );
}

export default AddContestantPage;