import PositionTemplate from "./resulttemplate";
import presImg from "../../../vlcsnap-error029.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Vote } from "../../../api";
import Toast from "../../../components/Toast";
import PreLoader from "../../../components/PreLoader";
import logo from "../../../logo.png"

const ResultPage = () => {
        const navigate = useNavigate();
        const [loading , setLoading] = useState(true);
        const [result, setResults] = useState({});
        const [positions, setPositions] = useState([]);
        const [voteOn, setVoteOn] = useState(true);
    
        useEffect(() => {
                const loadUpdatedVote = () => {
                        setLoading(true)
                        Vote.getVoteResults()
                        .then((res) => {
                                setLoading(false);
                                if (res.type !== "success") {
                                return Toast.fire({icon: "error", title: res.message})
                                .then(() => navigate("/admin/dashboard"));
                                }
                                setResults(res.summary);
                                setPositions(Object.keys(res.summary));
                        })
                        .catch((err) => {
                                setLoading(false);
                                const message = err.response?.data.message || "Network error";
                                Toast.fire({icon: "error", title: message})
                                .then(() => navigate("/admin/dashboard"));;
                        })
                }

                loadUpdatedVote();
                
    const intervalId = setInterval(() => {
        loadUpdatedVote();
        Vote.getVoteSetting()
        .then((res) => {
                if (!res.voteStatus) {
                        clearInterval(intervalId);
                        setVoteOn(false);
                }
        })
      }, 60000); 

      return () => clearInterval(intervalId);

        }, [])

        return (
                <>
                <p style={{textAlign: "center"}}>
                            <img src={logo} alt="NAOSS logo" width={150} />
                    </p>
                    <h1 style={{textAlign: "center", color: "rgb(34, 149, 8)"}}>ELECTION LIVE RESULT</h1>
                    <p style={{textAlign: "center"}}>{voteOn ? "Updated every 60 seconds." : "VOTING HAS ENDED. BELOW IS THE FINAL RESULT."}</p>
                <section className="welcome con">
                
                {
                    loading && <PreLoader message={"Fetching update vote results"} />
                }
                        <section className="contest">

                        {
                                loading && <h3 style={{textAlign:"center", padding: "30px 0px"}}>Fetching results...</h3>
                        }
                        <div>
                                {
                                        positions.map((post, id) => {
                                                return (
                                                <PositionTemplate 
                                                title=""
                                                showTitle={true}
                                                post={post} 
                                                contestants={result[post]}
                                                key={id}
                                                />

                                                )
                                        })
                                }
                        </div>  
                        
                        </section>    
                </section>
                </>
                
              
        );
};

export default ResultPage;