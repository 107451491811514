import PositionTemplate from "./resulttemplate";
import presImg from "../../../vlcsnap-error029.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Vote } from "../../../api";
import Toast from "../../../components/Toast";
import PreLoader from "../../../components/PreLoader";

const ResultPage = () => {
        const navigate = useNavigate();
        const [loading , setLoading] = useState(true);
        const [result, setResults] = useState({});
        const [positions, setPositions] = useState([]);
    
        useEffect(() => {
            if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
                return navigate("/admin/login");
            }

        Vote.getVoteResults()
            .then((res) => {
                setLoading(false);
                if (res.type !== "success") {
                    return Toast.fire({icon: "error", title: res.message})
                    .then(() => navigate("/admin/dashboard"));
                }
                setResults(res.summary);
                setPositions(Object.keys(res.summary));
            })
            .catch((err) => {
                setLoading(false);
                const message = err.response?.data.message || "Network error";
                Toast.fire({icon: "error", title: message})
                .then(() => navigate("/admin/dashboard"));;
            })

        }, [])

        return (
                <section className="welcome con">
                {
                    loading && <PreLoader message={"Loading vote results"} />
                }
                        <nav className="nav">
                                <ul>
                                        <li><Link to="/admin/dashboard" className="side">Dashboard</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/posts" className="side" >Post</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/contestants" className="side">Contestants</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/result" className="side">Result</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/status" className="side">Voters</Link></li>
                                </ul>
                        </nav>
                        <section className="contest">

                        {
                                loading && <h3 style={{textAlign:"center", padding: "30px 0px"}}>Fetching results...</h3>
                        }
                        <div>
                                {
                                        positions.map((post, id) => {
                                                return (
                                                <PositionTemplate 
                                                title=""
                                                showTitle={true}
                                                post={post} 
                                                contestants={result[post]}
                                                key={id}
                                                />

                                                )
                                        })
                                }
                        </div>  
                        
                        </section>    
                </section>
                
              
        );
};

export default ResultPage;