import PositionTemplate from "./positiontemplate";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Candidate, Position, Vote } from "../../../api";
import Toast from "../../../components/Toast";
import Swal from "sweetalert2";
import PreLoader from "../../../components/PreLoader";
import logo from "../../../logo.png";

const PositionsPage = () => {
    const [positions, setPositions] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [voteForm, setVoteForm] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Cookies.get("voting-tk") || Cookies.get("voting-tk").length < 10) {
            navigate("/login")
        }

        Position.getPositions()
        .then((res) => {
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message})
                .then(() => navigate("/admin/dashboard"));
            }
            setPositions(res.positions);
            const formTemp = res.positions.map(post => ({postId: post.id, postTitle: post.title, candidateId: "", candidateName: "" }))
            setVoteForm(formTemp);
            Candidate.getCandidates()
            .then((res) => {
                setLoading(false);
                if (res.type !== "success") {
                    Toast.fire({icon: "error", title: res.message})
                    .then(() => navigate("/admin/dashboard"));
                }
                setCandidates(res.candidates);
            })
            .catch((err) => {
                setLoading(false);
                const message = err.response?.data.message || "Network error";
                Toast.fire({icon: "error", title: message})
                .then(() => navigate("/admin/dashboard"));
            })

        })
        .catch((err) => {
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message})
            .then(() => navigate("/admin/dashboard"));
        })

    }, [])


    const candidateChange = (e) => {
        const formCopy = [...voteForm];
        const postObj = formCopy.find(post => post.postId == e.target.name);
        const selectedCandidate = candidates.find(c => c.id == e.target.value);
        postObj.candidateId = e.target.value;
        postObj.candidateName = selectedCandidate.first_name + " " + selectedCandidate.last_name;
        setVoteForm(formCopy);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const emptyCheck = voteForm.every(form => form.candidateId && form.candidateId.length > 0);
        if (!emptyCheck) {
                return Toast.fire({icon: "error", title: "Please select a candidate for every post."})
        }
        let confirmHTML = `
        <p>Confirm the following as your selected Candidates</p>
        `;
        voteForm.forEach(form => confirmHTML += `<p>${form.postTitle}: <b>${form.candidateName}</b>` );
        Swal.fire({
                icon: "info",
                title: "Confirm Vote",
                html: confirmHTML,
                showDenyButton: true,
                confirmButtonText: "Yes, cast vote"
        })
        .then(res => {
                if (res.isConfirmed) {
                        setSubmitLoading(true);
                        Vote.castVote({voteData: voteForm})
                        .then((res) => {
                                setSubmitLoading(false);
                                if (res.type !== "success") {
                                        Toast.fire({icon: "error", title: res.message});
                                }
                                Cookies.remove("voting-tk");
                                Cookies.remove("voter-tk");
                                Swal.fire({icon: "success", title: "You have voted", text: "Your vote has been successfully recorded. Vote results will be announced by the officials once the process is concluded."})
                                .then(() => navigate("/login"));
                        })
                        .catch((err) => {
                                setSubmitLoading(false);
                                console.log(err);
                                const message = err.response?.data.message || "Network error";
                                Toast.fire({icon: "error", title: message});
                        })
                }
        })
    }

        return (
                <>
                <div>
                <p style={{textAlign: "center"}}>
                        <img src={logo} alt="NAOSS logo" width={200} />
                </p>
                
                {
                    loading && <PreLoader message={"Fetching contestants data...Please wait"} />
                }

                {
                        positions.map((post, id) => {
                                return (
                                        <PositionTemplate 
                                        title=""
                                        showTitle={true}
                                        post={post.title} 
                                        contestants={candidates.filter(c => c.position == post.id)} 
                                        key={id}
                                        candidateChange={candidateChange}
                                        />
                                )
                        })
                }
                </div>  
                <div className="div">
                        {
                                !loading && <button onClick={handleSubmit} className="link vote">{submitLoading ? "Submitting your Vote..." : "Cast Vote"}</button> 
                        }
                          
                </div>
                   
                </>
              
        );
};

export default PositionsPage;