import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Position } from "../../../api";
import Toast from "../../../components/Toast";
import PreLoader from "../../../components/PreLoader";

const Posts = () => {
    const [loading , setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
            return navigate("/admin/login")
        }

        Position.getPositions()
        .then((res) => {
        setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message})
                .then(() => navigate("/admin/dashboard"));
            }
            setPosts(res.positions);
        })
        .catch((err) => {
            setLoading(false);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message})
            .then(() => navigate("/admin/dashboard"));
        })
    }, [])



    return (
        <section className="posts welcome">
        {
            loading && <PreLoader message={"Loading posts"} />
        }
            <nav>
                <ul>
                    <li><Link to="/admin/dashboard" className="side" >Dashboard</Link></li>
                    <hr/>
                    <li><Link to="/admin/posts" className="side" >Post</Link></li>
                    <hr/>
                    <li><Link to="/admin/contestants" className="side">Contestants</Link></li>
                    <hr/>
                    <li><Link to="/admin/result" className="side">Result</Link></li>
                    <hr/>
                    <li><Link to="/admin/status" className="side">Voters</Link></li>
                </ul>
            </nav>
            <article>
                <h1>Posts</h1>
                {
                    loading ?
                    <h3 style={{textAlign:"center"}}>Fetching posts... </h3>
                    :
                    <ul>
                        {
                           posts.map((p, index) => <li key={index}>{p.title} -- {p.registered_candidates} candidate(s)</li>) 
                        }
                        
                        <li><Link to="/admin/addpost" className="add">Add Post</Link></li>
                    </ul>
                }
            </article>
            
        </section>
    );
}

export default Posts;
