import React from 'react';
import ContestantInfo from "./contestantinfo";
import userImg from "../../../user.jpg";
import { serverurl } from '../../../api';

const PositionTemplate = ({ title, showTitle, post, contestants }) => {

    return (
        <>
        {showTitle && <h1 className='title'>{title}</h1>}
        <section className="all position">
            <section>
                <div className="log">
                    <h1>{post}</h1>
                </div>
                <p>List of contestant for {post} position</p>
                <hr/>
                {contestants.map((contestant, index) => (
                <ContestantInfo
                    key={index} 
                    image={contestant.profile_pic_url ? `${serverurl}/candidates_pics/${contestant.profile_pic_url}` : userImg}
                    votes={contestant.votes.length} 
                    contestantName={contestant.first_name + " " + contestant.last_name}
                    description={contestant.nick_name} 
                />
            ))}
            </section>
        </section>
        </>
        
    )
}

export default PositionTemplate;