import PositionTemplate from "./positiontemplate";
import presImg from "../../../../vlcsnap-error029.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Candidate, Position } from "../../../../api";
import Toast from "../../../../components/Toast";
import PreLoader from "../../../../components/PreLoader";

const ContestantPage = () => {

    const navigate = useNavigate();
    const [loading , setLoading] = useState(true);
    const [positions, setPositions] = useState([]);
    const [contestants, setContestants] = useState([]);

    useEffect(() => {
        if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
            return navigate("/admin/login");
        }

        Position.getPositions()
        .then((res) => {
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message})
                .then(() => navigate("/admin/dashboard"));
            }
            setPositions(res.positions);

            Candidate.getCandidates()
            .then((res) => {
                setLoading(false);
                if (res.type !== "success") {
                    Toast.fire({icon: "error", title: res.message})
                    .then(() => navigate("/admin/dashboard"));
                }
                setContestants(res.candidates);
            })
            .catch((err) => {
                setLoading(false);
                const message = err.response?.data.message || "Network error";
                Toast.fire({icon: "error", title: message})
                .then(() => navigate("/admin/dashboard"));;
            })
        })
        .catch((err) => {
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message})
            .then(() => navigate("/admin/dashboard"));;
        })
    }, [])

        return (
                <section className="welcome con">
                    {
                        loading && <PreLoader message={"Loading contestants"} />
                    }
                        <nav className="nav">
                                <ul>
                                        <li><Link to="/admin/dashboard" className="side">Dashboard</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/posts" className="side" >Post</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/contestants" className="side">Contestants</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/result" className="side">Result</Link></li>
                                        <hr/>
                                        <li><Link to="/admin/status" className="side">Voters</Link></li>
                                </ul>
                        </nav>
                        <section className="contest">
                        <div className="div third">
                            <Link to="/admin/addcontestant" className="link vote">Add Contestant</Link>        
                        </div>
                        
                        <div>
                                {
                                        positions.map((post, id) => {
                                                return (
                                                <PositionTemplate 
                                                showTitle={true}
                                                post={post.title} 
                                                contestants={contestants.filter(c => c.position == post.id)} 
                                                />
                                                )
                                        })
                                }
                        </div> 
                        </section>
                </section>
                
                   
              
        );
};

export default ContestantPage;