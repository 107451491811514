import { Link,useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect , useState} from "react";
import { Position } from "../../../api";
import Toast from "../../../components/Toast";


const AddPostPage = () => {
    const [form, setForm] = useState({title: "", description: ""});
    const [loading , setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Cookies.get("admin-tk") || Cookies.get("admin-tk").length < 10) {
            navigate("/admin/login")
        }
    }, [])

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        Position.addPosition(form)
        .then((res) => {
        setLoading(false);
            if (res.type !== "success") {
                Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: res.message})
            .then(() => navigate("/admin/posts"));
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            const message = err.response?.data.message || "Network error";
            Toast.fire({icon: "error", title: message});
        })
    }

    return (
        <section className="all">
            <div className="log">
                <h1>Add post</h1>   
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <p>Post name</p>
                    <input type="text" name="title" onChange={inputChange} />
                </div>
                <div>
                    <p>Post description</p>
                    <input type="text" name="description" onChange={inputChange} />
                </div>
                <div>
                    <button type="submit" className="link login">{loading ? "Submitting..." : "Submit"}</button>
                </div>
            </form>
            <Link to={-1} >&lt;&lt; Back</Link>
        </section>
    );
}

export default AddPostPage;